<template>
  <div>
    <b-container>
      <b-row>
        <h1 id="headline" class="page-header mr-auto">{{ title }}</h1>
        <div class="align-self-center mr-1">
          <div v-if="isUserAdmin" class="btn-group">
            <b-button v-if="selectedReportIds.length > 0" v-b-modal="'chooseNewOwnerMultiple'" class="btn-outline-info mr-1">
              <font-awesome-icon icon="user"/>
              Change Owner
            </b-button>
            <b-button v-if="selectedReportIds.length > 0" variant="outline-dark" @click="clearReportSelection">
              <span v-if="selectedReportIds.length === 1"> 1 report selected</span>
              <span v-else>{{ selectedReportIds.length }} reports selected</span>
            </b-button>
          </div>
        </div>
        <div class="align-self-center">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <font-awesome-icon icon="search"/>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="filter" debounce="500" placeholder="Search" type="text"
                          @input="doFullTextSearch=false"></b-form-input>
          </b-input-group>
        </div>
      </b-row>
    </b-container>
    <b-modal id="chooseNewOwnerMultiple" title="Choose new report owner" @show="fetchUsers" @ok="changeOwner()">
      <div id="getUsers" class="card-body form-horizontal">
        <div class="form-group row">
          <label class="col-form-label">Users:</label>
          <b-form-select v-model="selectedUser" :options="activeUsers"></b-form-select>
        </div>
      </div>
    </b-modal>
    <b-table
        id="reports-table"
        ref="reports-table"
        :busy.sync="isBusy"
        :current-page="currentPage"
        :fields="fields"
        :filter="filter"
        :items="reportsProvider"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        fixed
        responsive="sm"
        show-empty
        small
        striped
    >
      <template #head(ReportHeader)="data">
        {{ data.label }}<br>
        <span class="text-muted font-weight-normal">
          <a href="#" @click="setSortBy('report_label')">
            Report Label
            <font-awesome-icon :icon="getSortByClass('report_label')"/>
          </a>
        </span><br>
        <span class="text-muted font-weight-normal">
          <a href="#" @click="setSortBy('report_creation')">
            Timestamp
            <font-awesome-icon :icon="getSortByClass('report_creation')"/>
          </a>
        </span><br>
        <span class="text-muted font-weight-normal">
          <a href="#" @click="setSortBy('user_label')">
            Uploader
            <font-awesome-icon :icon="getSortByClass('user_label')"/>
          </a>
        </span><br>
        <span class="text-muted font-weight-normal">
          <a href="#" @click="setSortBy('report_author_label')">
            Technician
            <font-awesome-icon :icon="getSortByClass('report_author_label')"/>
          </a>
        </span><br>
        <span class="text-muted font-weight-normal">
          <a href="#" @click="setSortBy('report_flavor')">
            Flavor
            <font-awesome-icon :icon="getSortByClass('report_flavor')"/>
          </a>
        </span>
      </template>
      <template #cell(ReportHeader)="data">
        <router-link :to="'/report/' + data.item.report_longid">
          <font-awesome-icon class="mr-1" icon="list-alt"/>
          <span v-if="data.item.report_label">{{ data.item.report_label }}</span>
          <i v-else>Unlabeled</i>
        </router-link>
        <br>
        <font-awesome-icon class="mr-1" icon="calendar-alt"/>
        {{ data.item.report_creation }}<br>
        <div v-if="data.item.user_restricted"  class="text-warning">
          <font-awesome-icon icon="user-lock" title="This report has been uploaded by a restricted user"/>
          {{ data.item.user_label }}<br>
        </div>
        <div v-else>
          <font-awesome-icon icon="user"/>
          {{ data.item.user_label }}<br>
        </div>

        <font-awesome-icon class="mr-1" icon="wrench"/>
        {{ data.item.report_author_label }}<br>
        <font-awesome-icon class="mr-1" icon="glass-martini"/>
        {{ data.item.report_flavor }}
      </template>
      <template #head(ScreenInformation)="data">
        {{ data.label }}<br>
        <span class="text-muted font-weight-normal">
          <a href="#" @click="setSortBy('machine_type')">
            Machine Type
            <font-awesome-icon :icon="getSortByClass('machine_type')"/>
          </a>
        </span><br>
        <span class="text-muted font-weight-normal">
          <a href="#" @click="setSortBy('machine_serial')">
            Machine Serial
            <font-awesome-icon :icon="getSortByClass('machine_serial')"/>
          </a>
        </span><br>
        <span class="text-muted font-weight-normal">
          <a href="#" @click="setSortBy('machine_owner_label')">
            Machine Owner
            <font-awesome-icon :icon="getSortByClass('machine_owner_label')"/>
          </a>
        </span>
      </template>
      <template #cell(ScreenInformation)="data">
        <font-awesome-icon class="mr-1" icon="tag"/>
        {{ data.item.machine_type }}<br>
        <div v-if="isUserViewAll">
          <router-link :to="'/machine/' + data.item.machine_serial">
            <font-awesome-icon class="mr-1" icon="barcode"/>
            {{ data.item.machine_serial }}
          </router-link>
        </div>
        <div v-else>
          <div>
            <font-awesome-icon class="mr-1" icon="barcode"/>
            {{ data.item.machine_serial }}
          </div>
          <font-awesome-icon class="mr-1" icon="briefcase"/>
          {{ data.item.machine_owner_label }}
        </div>
        <b-form-checkbox
            v-if="isUserAdmin"
            v-model="selectedReportIds"
            :value="data.item.report_longid"
            class="text-right"
        ></b-form-checkbox>
      </template>
      <template #empty="">
        <div aria-live="polite" role="alert">
          <div class="text-center my-2">
            <b-spinner v-if="isBusy" :style="'visibility: ' + (isBusy ? 'visible': 'hidden')" label="Loading..."
                       variant="secondary"></b-spinner>
            <span v-else>There are no records matching your request</span>
          </div>
        </div>
      </template>
      <template #emptyfiltered="">
        <div aria-live="polite" role="alert">
          <div class="text-center my-2">
            <span>There are no records matching your request</span>
          </div>
        </div>
      </template>
    </b-table>
    <div v-if="statusText" class="mb-3">
      {{ statusText }}
    </div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <span class="text-muted">
        {{ totalRows }} results
        <b-spinner :style="'visibility: ' + (isBusy ? 'visible': 'hidden')" label="Loading..." small
                   variant="secondary"></b-spinner>
      </span>
      <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          aria-controls="reports-table"
          class="mb-0"
          first-number
          last-number
          limit="7"
          size="sm"
      ></b-pagination>
      <b-form inline>
        <span class="text-muted">Show</span>
        <b-form-select v-model="perPage" :options="perPageOptions" class="ml-2" size="sm"></b-form-select>
      </b-form>
    </div>
    <b-alert v-if="this.filter && this.filter.length > 0 && doFullTextSearch === false && this.isBusy === false" :show="true"
             variant="secondary">
      <font-awesome-icon icon="search"/>
      Did not find what you were looking for?
      <a href="#" @click="doFullTextSearch=true; $refs['reports-table'].refresh();">
        Click here to run a slow full-text search.
      </a>
    </b-alert>
    <div class="text-center mb-3">
    </div>
  </div>
</template>

<script>
import { reportsApi, usersApi } from '@/backend'
import { mapGetters } from 'vuex'
import { ChangeReportsOwnersQuery } from '@/screencheck_portal_api/src'

export default {
  name: 'ReportsPage',
  props: ['isAllReports'],
  data: function () {
    return {
      statusText: null,
      totalRows: 0,
      isBusy: false,
      fields: [
        { key: 'ReportHeader', thAttr: { 'colspan': '5' }, tdAttr: { 'colspan': '5' } },
        { key: 'ScreenInformation', thAttr: { 'colspan': '5' }, tdAttr: { 'colspan': '5' } },
        { key: 'report_label', label: 'Report Label', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'report_creation', label: 'Timestamp', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'user_label', label: 'Uploader', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'report_author_label', label: 'Technician', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'report_flavor', label: 'Flavor', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'machine_type', label: 'Screen Type', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'machine_serial', label: 'Screen Serial Number', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'machine_owner_label', label: 'Screen Owner', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'report_selection_checkbox', label: 'Select Report', sortable: false, thClass: 'd-none', tdClass: 'd-none' }
      ],
      filter: '',
      sortBy: null,
      sortDesc: false,
      perPage: 25,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      doFullTextSearch: false,
      selectedUser: null,
      activeUsers: null,
      selectedReportIds: []
    }
  },
  mounted () {
    this.$refs['reports-table'].refresh()
  },
  computed: {
    ...mapGetters(['isUserAdmin']),
    isUserViewAll: function () {
      return this.$store.getters.isUserViewAll
    },
    title: function () {
      if (this.isAllReports) {
        return 'All Reports'
      } else {
        return 'My Reports'
      }
    }
  },
  watch: {
    '$route.path': function () {
      this.$refs['reports-table'].refresh()
    }
  },
  methods: {
    reportsProvider: function (ctx, callback) {
      let query = {
        filter: ctx.filter,
        sortBy: ctx.sortBy,
        sortDesc: ctx.sortDesc,
        currentPage: ctx.currentPage,
        perPage: ctx.perPage,
        doFullTextSearch: this.doFullTextSearch
      }

      const reportsHandler = (error, data) => {
        if (error) {
          this.statusText = 'Error transferring reports'
          console.error(error)
          callback([])
          this.isBusy = false
          return
        }

        data.reports.forEach(report => {
          if (report.user_restricted) {
            report._rowVariant = 'warning'
          }
        })
        let reports = data.reports
        this.totalRows = data.totalRows
        this.statusText = null
        callback(reports)
        this.isBusy = false
      }

      this.isBusy = true
      if (this.isAllReports) {
        reportsApi.getAllReports(query, reportsHandler)
      } else {
        reportsApi.getMyReports(query, reportsHandler)
      }

      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    setSortBy: function (sortBy) {
      if (this.sortBy === sortBy) {
        this.sortDesc = !this.sortDesc
      } else {
        this.sortBy = sortBy
        this.sortDesc = false
      }
    },
    getSortByClass: function (btnSortBy) {
      if (btnSortBy === this.sortBy) {
        return (this.sortDesc ? 'sort-down' : 'sort-up')
      } else {
        return 'sort'
      }
    },
    fetchUsers: function () {
      usersApi.getUsers((error, data) => {
        if (error) {
          console.error(error)
          this.statusText = 'Error getting users'
          return
        }
        this.activeUsers = data
            .filter(user => user.status === 'active')
            .map(user => ({
              value: user.longid,
              text: user.label + ' (' + user.email + ')'
            }))
        this.statusText = null
      })
    },
    changeOwner: function () {
      if (this.selectedUser) {
        const query = ChangeReportsOwnersQuery.constructFromObject({
          reportIds: this.selectedReportIds,
          userId: this.selectedUser
        })
        reportsApi.changeReportsOwners(query, (error) => {
          if (error) {
            console.error('Failed to change report owner because: ' + error)
            return
          }
          this.$refs['reports-table'].refresh()
        })
      }
    },
    clearReportSelection: function () {
      this.selectedReportIds = []
    }
  }
}
</script>
