<template>
  <div>
    <open-reviews class="float-right" v-show="this.machine?.openReviews?.count > 0" :review-count="this.machine?.openReviews?.count" :serial="machineSerial"/>
    <h1 class="page-header">Machine File {{ machineSerial }} </h1>
    <div v-if="machineFile" class="row">
      <div class="col-md-6">
        <static-data :data="machineFile" :editable="true" @onChange="applyChanges"></static-data>
        <weight-data :data="machineFile" :editable="true" @onChange="applyChanges"></weight-data>
        <application-data :data="machineFile" :editable="true" @onChange="applyChanges"></application-data>
        <media-data :data="machineFile" :editable="true" @onChange="applyChanges"></media-data>
      </div>
      <div class="col-md-6">
        <suspension-data :data="machineFile" :editable="true" @onChange="applyChanges"></suspension-data>
        <lubrication-data :data="machineFile" :editable="true" @onChange="applyChanges"></lubrication-data>
        <motor-data :data="machineFile" :editable="true" @onChange="applyChanges"></motor-data>
        <revision-data :data="machineFile" :editable="true" @onChange="applyChanges"></revision-data>
      </div>
    </div>
    <div v-else class="text-center">
      <span v-if="errorText">{{ errorText }}</span>
      <b-spinner v-else label="Loading..." show variant="secondary"></b-spinner>
    </div>
    <div class="btn-group float-right mb-5" v-if="userHasRole && machineFile">
      <b-button @click="saveChanges" size="sm" variant="outline-dark">
        <font-awesome-icon icon="check"/>
        Save
      </b-button>
    </div>
    <b-toast ref="successToast" variant="success" fade no-title no-close-button auto-hide-delay="3000">
      Machine File saved successfully.
    </b-toast>
  </div>
</template>

<script>
import OpenReviews from '@/components/machine_file/OpenReviews.vue'
import staticData from '@/components/machine_file/StaticData.vue'
import weightData from '@/components/machine_file/WeightData.vue'
import applicationData from '@/components/machine_file/ApplicationData.vue'
import mediaData from '@/components/machine_file/MediaData.vue'
import suspensionData from '@/components/machine_file/SuspensionData.vue'
import lubricationData from '@/components/machine_file/LubricationData.vue'
import motorData from '@/components/machine_file/MotorData.vue'
import revisionData from '@/components/machine_file/RevisionData.vue'
import { machinesApi } from '@/backend'
import { EditMachineQuery } from '@/screencheck_portal_api/src'
import { defaultMachineFile } from '@/utils/MachineFile'

export default {
  name: 'MachineFileEditPage',
  components: {
    OpenReviews,
    staticData,
    weightData,
    applicationData,
    mediaData,
    suspensionData,
    lubricationData,
    motorData,
    revisionData
  },
  data () {
    return {
      machine: null,
      machineFile: null,
      errorText: null
    }
  },
  mounted () {
    this.fetchMachine()
  },
  computed: {
    userHasRole: function () {
      return this.$store.getters.isUserAdmin || this.$store.getters.isUserEngineeringManager
    },
    machineSerial: function () {
      return this.$route.params.serial
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        if (to.name === 'machine' && this.$route.params.serial !== undefined) {
          this.fetchMachine()
        }
      }
    }
  },
  methods: {
    fetchMachine: function () {
      this.machine = null
      this.machineFile = null
      machinesApi.getMachine(this.machineSerial, (error, data) => {
        if (error) {
          this.errorText = 'Failed fetching machine data'
          console.error('Failed fetching machine because: ' + error)
          return
        }
        this.machine = data
        this.errorText = null
        this.fetchMachineFileDetails()
      })
    },
    fetchMachineFileDetails: function () {
      this.machineFile = null
      machinesApi.getMachineFileDetails(this.machineSerial, (error, data) => {
        if (error) {
          this.errorText = 'Failed fetching machine file data'
          console.error('Failed fetching machine file data because: ' + error)
          return
        }
        if (Object.keys(data).length === 0) {
          console.error('No machine file data available')
        }
        this.machineFile = { ...defaultMachineFile(this.machineSerial), ...data }
        this.errorText = null
      })
    },
    applyChanges: function (machineFile) {
      this.machineFile = machineFile
    },
    saveChanges: function () {
      const query = EditMachineQuery.constructFromObject({
        serial: this.machineFile.static.machineSerialNumber,
        type: this.machineFile.static.machineType,
        owner: this.machineFile.static.customerName,
        data: this.machineFile
      })
      machinesApi.editMachine(this.machineSerial, query, (error) => {
        if (error) {
          this.errorText = 'Failed saving machine file data'
          console.error('Failed saving machine file data because: ' + error)
          return
        }
        this.$refs.successToast.show()
        if (this.machineFile.static.machineSerialNumber !== this.machineSerial) {
          this.$router.push('/machine/' + this.machineFile.static.machineSerialNumber + '/edit');
        }
      })
    }
  }
}
</script>

<style lang="scss">
</style>
