/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PrintedUser model module.
 * @module model/PrintedUser
 * @version 0.0.1
 */
class PrintedUser {
    /**
     * Constructs a new <code>PrintedUser</code>.
     * @alias module:model/PrintedUser
     */
    constructor() { 
        
        PrintedUser.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PrintedUser</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PrintedUser} obj Optional instance to populate.
     * @return {module:model/PrintedUser} The populated <code>PrintedUser</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PrintedUser();

            if (data.hasOwnProperty('is_admin')) {
                obj['is_admin'] = ApiClient.convertToType(data['is_admin'], 'Boolean');
            }
            if (data.hasOwnProperty('is_viewall')) {
                obj['is_viewall'] = ApiClient.convertToType(data['is_viewall'], 'Boolean');
            }
            if (data.hasOwnProperty('is_engineering_manager')) {
                obj['is_engineering_manager'] = ApiClient.convertToType(data['is_engineering_manager'], 'Boolean');
            }
            if (data.hasOwnProperty('is_raw_data')) {
                obj['is_raw_data'] = ApiClient.convertToType(data['is_raw_data'], 'Boolean');
            }
            if (data.hasOwnProperty('valid')) {
                obj['valid'] = ApiClient.convertToType(data['valid'], 'Boolean');
            }
            if (data.hasOwnProperty('label')) {
                obj['label'] = ApiClient.convertToType(data['label'], 'String');
            }
            if (data.hasOwnProperty('longid')) {
                obj['longid'] = ApiClient.convertToType(data['longid'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PrintedUser</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PrintedUser</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['label'] && !(typeof data['label'] === 'string' || data['label'] instanceof String)) {
            throw new Error("Expected the field `label` to be a primitive type in the JSON string but got " + data['label']);
        }
        // ensure the json data is a string
        if (data['longid'] && !(typeof data['longid'] === 'string' || data['longid'] instanceof String)) {
            throw new Error("Expected the field `longid` to be a primitive type in the JSON string but got " + data['longid']);
        }

        return true;
    }


}



/**
 * @member {Boolean} is_admin
 */
PrintedUser.prototype['is_admin'] = undefined;

/**
 * @member {Boolean} is_viewall
 */
PrintedUser.prototype['is_viewall'] = undefined;

/**
 * @member {Boolean} is_engineering_manager
 */
PrintedUser.prototype['is_engineering_manager'] = undefined;

/**
 * @member {Boolean} is_raw_data
 */
PrintedUser.prototype['is_raw_data'] = undefined;

/**
 * @member {Boolean} valid
 */
PrintedUser.prototype['valid'] = undefined;

/**
 * @member {String} label
 */
PrintedUser.prototype['label'] = undefined;

/**
 * @member {String} longid
 */
PrintedUser.prototype['longid'] = undefined;






export default PrintedUser;

