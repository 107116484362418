<template>
  <div v-if="!isBusy">
    <div class="page-header d-flex align-items-center justify-content-between">
      <h1 v-if="isOwner">My Report</h1>
      <h1 v-else>Report Shared by {{ report.user_label }}</h1>
      <div v-if="isUserAdmin" class="btn-toolbar">
        <div class="btn-group">
          <b-button v-b-modal="'chooseNewOwnerSingle'" class="btn-outline-info mr-1">
            <font-awesome-icon icon="user"/>
            Change Owner
          </b-button>
          <b-button v-if="reportVisible" v-b-modal="'hideReport'" class="btn-outline-info">
            <font-awesome-icon icon="eye-slash"/>
            Hide
          </b-button>
          <b-button v-else v-b-modal="'unhideReport'" class="btn-outline-info">
            <font-awesome-icon icon="eye"/>
            Unhide
          </b-button>
        </div>
      </div>
    </div>
    <b-alert :show="report.user_role === 'restricted'" variant="warning">
      This report has been uploaded by a restricted user
    </b-alert>
    <b-modal id="chooseNewOwnerSingle" title="Choose new report owner" @show="fetchUsers" @ok="changeOwner()">
      <div id="getUsers" class="card-body form-horizontal">
        <div class="form-group row">
          <label class="col-form-label">Users:</label>
          <b-form-select v-model="selectedUser" :options="activeUsers"></b-form-select>
        </div>
      </div>
    </b-modal>
    <b-modal id="hideReport" title="Hide Report" @ok="toggleReportHidden(true)">
      Are you sure you want to hide this report?
    </b-modal>
    <b-modal id="unhideReport" title="Unhide Report" @ok="toggleReportHidden(false)">
      Are you sure you want to unhide this report?
    </b-modal>
    <b-alert :show="!reportVisible" variant="warning">
      <font-awesome-icon class="mr-2" icon="eye-slash"/>
      This report has been hidden. Information on this page might be inaccessible.
    </b-alert>
    <div class="row">
      <div class="col-sm-4">
        <div class="card">
          <div class="card-header">
            Report Header
          </div>
          <div class="card-body">
            <div>
              <b>
                <font-awesome-icon icon="list-alt"/>
                Label
              </b><br>
              <span v-if="report.report_label">{{ report.report_label }}</span>
              <i v-else>Unlabeled</i>
            </div>
            <div class="mt-3">
              <b>
                <font-awesome-icon icon="calendar-alt"/>
                Timestamp
              </b><br>
              {{ report.report_creation }}
            </div>
            <div v-if="!isOwner" class="mt-3">
              <div v-if="report.user_role === 'restricted'" class="text-warning">
                <b>
                  <font-awesome-icon  icon="user-lock" title="This report has been uploaded by a restricted user"/>
                  Uploader
                </b><br>
                <span :title="report.user_longid">{{ report.user_label }}</span>
              </div>
              <div v-else>
                <b>
                  <font-awesome-icon icon="user"/>
                  Uploader
                </b><br>
                <span :title="report.user_longid">{{ report.user_label }}</span>
              </div>
            </div>
            <div class="mt-3">
              <b>
                <font-awesome-icon icon="wrench"/>
                Technician
              </b><br>
              {{ report.report_author_label }}
            </div>
            <div v-if="report.report_flavor" class="mt-3">
              <b>
                <font-awesome-icon icon="glass-martini"/>
                Flavor
              </b><br>
              {{ report.report_flavor }}
            </div>
          </div>
        </div>
        <div v-if="report.report_attachments_images" class="card">
          <div class="card-header">
            Images
          </div>
          <div class="card-body">
            <div class="gallery">
              <div v-for="image in report.report_attachments_images" :key="image.image">
                <a :data-lightbox="report.report_longid"
                   :href="reportPath + image.image">
                  <img :src="reportPath + image.image" alt="Image thumbnail" style="width: 60px; height:60px; object-fit: cover;">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="report.report_attachments_xls_file" class="card">
          <div class="card-header">
            Relevant Files
          </div>
          <div class="card-body">
            <div>
              <a :href="reportPath + report.report_attachments_xls_file + '/' + report.report_attachments_xls_filename">
                <font-awesome-icon icon="download"/>
                XLS Report
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card">
          <div class="card-header">
            Screen Information
          </div>
          <div class="card-body">
            <div v-if="report.machine_type" class="mb-3">
              <b>
                <font-awesome-icon icon="tag"/>
                Screen Type
              </b><br>
              {{ report.machine_type }}
            </div>
            <div v-if="report.machine_serial" class="mb-3">
              <b>
                <font-awesome-icon icon="barcode"/>
                Screen Serial Number
              </b><br>
              <router-link v-if="isUserAdmin || isUserViewAll" :to="'/machine/' + report.machine_serial + '/'">
                {{ report.machine_serial }}
              </router-link>
              <span v-else>{{ report.machine_serial }}</span>
            </div>
            <div v-if="report.machine_owner_label" class="mb-3">
              <b>
                <font-awesome-icon icon="briefcase"/>
                Screen Owner
              </b><br>
              {{ report.machine_owner_label }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="isUserRawData" class="col-sm-4">
        <div class="card">
          <div class="card-header">
            Raw Data
          </div>
          <div class="card-body">
            <div class="mb-3">
              <b>
                <font-awesome-icon icon="cloud-upload-alt"/>
                Available
              </b>
              <div v-if="rawdata.available.length !== 0">
                <div class="d-flex align-items-center" v-for="rawDataEntry in rawdata.available" :key="rawDataEntry.uuid">
                  {{ localPrettifyType(rawDataEntry.type) }} <span
                    class="text-muted ml-1">{{ localShortenUuid(rawDataEntry.uuid) }}</span>
                  <a :href="'/frontend-api/rawdata/download/'+rawDataEntry.uuid+'/json'" class="ml-1" title="Download JSON">
                    <font-awesome-icon icon="file-code"/>
                  </a>
                  <a :href="'/frontend-api/rawdata/download/'+rawDataEntry.uuid+'/csv'" class="ml-1" title="Download CSV">
                    <font-awesome-icon icon="file-csv"/>
                  </a>
                  <measurement-issues class="ml-1" v-if="rawDataEntry.measurement_issues?.length > 0" :issues="rawDataEntry.measurement_issues"/>
                </div>
              </div>
              <div v-else><span class="text-muted">None</span></div>
            </div>
            <div>
              <b>
                <font-awesome-icon icon="times-circle"/>
                Unavailable
              </b>
              <b-alert v-if="requestRawDataFailed !== null" variant="danger" show>
                <font-awesome-icon class="mr-2" icon="exclamation-triangle"/>
                Failed to request raw data, because: {{ requestRawDataFailed }}
              </b-alert>
              <div v-if="rawdata.unavailable.length !== 0">
                <div v-for="rawDataEntry in rawdata.unavailable" :key="rawDataEntry.uuid">
                  {{ localPrettifyType(rawDataEntry.type) }}
                  <span class="text-muted">{{ localShortenUuid(rawDataEntry.uuid) }}</span>
                  <span v-if="rawDataEntry.irretrievable" class="ml-1">(irretrievable)</span>
                  <span v-else-if="rawDataEntry.requested" class="ml-1">(requested)</span>
                  <span v-else-if="!rawDataEntry.requested && !rawdata.irretrievable" class="ml-1">
                    <a href="#" @click.prevent="requestRawData(rawDataEntry)">(request)</a>
                  </span>
                </div>
              </div>
              <div v-else><span class="text-muted">None</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isOwner && report.report_attachments_xls_file" class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header d-flex align-items-center justify-content-between">
            Share
            <button id="newaccesstoken" class="btn btn-outline-dark btn-sm" v-b-modal="'new-link-modal'">
              <font-awesome-icon icon="share-square"/>
              new access link
            </button>
          </div>
          <div class="card-body">
            <b-table v-if="reportShares" :fields="fields" :items="reportShares">
              <template #cell(edit)="data">
                <button :data-edit='{"longid": data.item.longid, "label": data.item.label, "expiry": data.item.expiry}'
                        class="editshare btn btn-outline-dark btn-sm" type="button"
                        @click="editShare(data.item)">
                  <font-awesome-icon icon="edit"/>
                  edit
                </button>
              </template>
              <template #cell(label)="data">
                <span :title="data.item.longid">{{ data.item.label }}</span>
              </template>
              <template #cell(token)="data">
                <router-link :to="'/report/' + report.report_longid + '/' + data.item.token" target="_blank">
                  link to shared report
                </router-link>
              </template>
              <template #cell(revoke)="data">
                <button class="revokesharelink btn btn-danger btn-sm float-right"
                        @click="showRevokeShareLinkBox(data.item)">
                  <font-awesome-icon icon="times"/>
                  revoke
                </button>
              </template>
            </b-table>
            <span v-else>Currently not shared</span>
          </div>
          <div class="card-footer">
            <div class="alert alert-info mb-0" role="alert">
              <font-awesome-icon icon="info-circle"/>
              You can easily share this report by generating a new access link. When you want to stop
              sharing this report, simply revoke access.
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="new-link-modal" ref="new-link-modal" title="New Access Link" @cancel="cancelShareButton" @ok="saveNewShareButton">
      <div id="new-link" class="form-horizontal">
        <div class="form-group row">
          <label for="newlabel" class="col-sm-3 col-form-label">Label</label>
          <div class="col-sm-9">
            <input id="newlabel" class="form-control" v-model="accessLinkInfo.label">
          </div>
        </div>
        <div class="form-group row">
          <label for="newexpiry" class="col-sm-3 col-form-label">Expiry</label>
          <div class="col-sm-9">
            <input id="newexpiry" class="form-control" data-provide="datepicker" placeholder="never" v-model="accessLinkInfo.expiry">
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="edit-link-modal" ref="edit-link-modal" title="Edit Access Link" @cancel="cancelShareButton" @ok="saveEditShareButton">
      <div id="edit-link" class="form-horizontal">
        <div class="form-group row">
          <label for="editlabel" class="col-sm-3 col-form-label">Label</label>
          <div class="col-sm-9">
            <input id="editlabel" class="form-control" v-model="accessLinkInfo.label">
          </div>
        </div>
        <div class="form-group row">
          <label for="editexpiry" class="col-sm-3 col-form-label">Expiry</label>
          <div class="col-sm-9">
            <input id="editexpiry" class="form-control" data-provide="datepicker" placeholder="never" v-model="accessLinkInfo.expiry">
          </div>
        </div>
      </div>
    </b-modal>
  </div>
  <div v-else class="text-center">
    <b-spinner label="Loading..." show variant="secondary"></b-spinner>
  </div>
</template>

<script>
import $jcbHelper from './../jcbHelper'
import { reportsApi, usersApi, rawdataApi } from '@/backend'
import { CreateReportShareTokenQuery, EditReportShareTokenQuery, RevokeReportShareTokenQuery, ChangeReportsOwnersQuery } from '../screencheck_portal_api/src'
import lightbox from 'lightbox2'
import 'lightbox2/src/css/lightbox.css'
import MeasurementIssues from '@/components/MeasurementIssues.vue'

export default {
  name: 'ReportPage',
  components: { MeasurementIssues },
  data () {
    return {
      isOwner: null,
      report: null,
      reportVisible: null,
      rawdata: null,
      reportShares: null,
      statusText: '',
      fields: [
        { key: 'edit', label: '', sortable: false },
        { key: 'label', label: 'Label', sortable: true },
        { key: 'expiry', label: 'Expiry', sortable: true },
        { key: 'token', label: 'Share Link', sortable: true },
        { key: 'revoke', label: '', sortable: false }
      ],
      accessLinkInfo: {
        label: '',
        expiry: '',
        token: '',
        longid: '',
      },
      selectedUser: null,
      activeUsers: null,
      requestRawDataFailed: null
    }
  },
  activated () {
    this.fetchReportData()

    lightbox.option({
      'fadeDuration': 0,
      'imageFadeDuration': 0,
      'resizeDuration': 0
    })
  },
  computed: {
    isUserAdmin: function () {
      return this.$store.getters.isUserAdmin
    },
    isUserViewAll: function () {
      return this.$store.getters.isUserViewAll
    },
    isUserRawData: function () {
      return this.$store.getters.isUserRawData
    },
    reportPath: function () {
      if (this.shareToken) {
        return '/frontend-api/report/' + this.reportLongId + '/' + this.shareToken + '/'
      } else {
        return '/frontend-api/report/' + this.reportLongId + '/_/'
      }
    },
    reportLongId: function () {
      return this.$route.params.longid
    },
    shareToken: function () {
      return this.$route.params.shareToken
    },
    isBusy: function () {
      return this.report === null || this.user === null
    }
  },
  methods: {
    localPrettifyType: function (type) {
      return $jcbHelper.prettifyType(type)
    },
    localShortenUuid: function (uuid) {
      return $jcbHelper.shortenUuid(uuid)
    },
    fetchReportData: function () {
      if (this.shareToken) {
        reportsApi.getReportWithShareToken(this.reportLongId, this.shareToken, (error, data) => {
          if (error) {
            console.error('Failed to get the report data for the report with the longid \'' + this.reportLongId + '\' because: ' + error)
            this.statusText = 'Error transferring data'
            return
          }
          this.report = data.report
          this.reportShares = data.report.shares
          this.reportVisible = data.visible
          this.rawdata = data.rawdata
          this.isOwner = data.isOwner
          this.statusText = null
        })
      } else {
        reportsApi.getReportWithoutShareToken(this.reportLongId, (error, data) => {
          if (error) {
            console.error('Failed to get the report data for the report with the longid \'' + this.reportLongId + '\' because: ' + error)
            this.statusText = 'Error transferring stats'
            return
          }
          this.report = data.report
          this.reportShares = data.report.shares
          this.reportVisible = data.visible
          this.rawdata = data.rawdata
          this.user = data.user
          this.isOwner = data.isOwner
          this.statusText = null
        })
      }
    },
    fetchUsers: function () {
      usersApi.getUsers((error, data) => {
        if (error) {
          console.error(error)
          this.statusText = 'Error getting users'
          return
        }
        this.activeUsers = data
            .filter(user => user.status === 'active')
            .map(user => ({
              value: user.longid,
              text: user.label + ' (' + user.email + ')'
            }))
        this.statusText = null
      })
    },
    saveEditShareButton: function () {
      const updateQuery = EditReportShareTokenQuery.constructFromObject({
        report: this.report.report_longid,
        token: this.accessLinkInfo.longid, // not sure why Access Link LongId is called token everywhere (frontend + backend)
        label: this.accessLinkInfo.label,
        expiry: this.accessLinkInfo.expiry
      })
      reportsApi.updateReportShareToken(updateQuery, (error) => {
        if (error) {
          console.error('Failed updating report share token because: ' + error)
          return
        }
        // Reset Input Fields and Prop
        this.cancelShareButton()
        this.fetchReportData()
      })
    },
    showRevokeShareLinkBox (accessLink) {
      this.$bvModal.msgBoxConfirm('Share link "' + accessLink.label + '" will not grant access to this report anymore.', {
        title: 'Revoking access to report',
      }).then(value => {
        if (value) {
          const revokeQuery = RevokeReportShareTokenQuery.constructFromObject({
            report: this.report.report_longid,
            token: accessLink.longid.trim()
          })
          reportsApi.revokeReportShareToken(revokeQuery, (error) => {
            if (error) {
              console.error('Failed to revoke report share token because: ' + error)
              return
            }
            // fetch Data
            this.fetchReportData()
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    saveNewShareButton: function () {
      const createQuery = CreateReportShareTokenQuery.constructFromObject({
        report: this.report.report_longid,
        label: this.accessLinkInfo.label,
        expiry: this.accessLinkInfo.expiry
      })
      reportsApi.updateReportShareToken(createQuery, (error) => {
        if (error) {
          console.error('Failed creating report share token because: ' + error)
          return
        }
        // Reset Input Fields and Prop
        this.cancelShareButton()
        this.fetchReportData()
      })
    },
    changeOwner: function () {
      if (this.selectedUser) {
        const query = ChangeReportsOwnersQuery.constructFromObject({
          reportIds: [ this.reportLongId ],
          userId: this.selectedUser
        })
        reportsApi.changeReportsOwners(query, (error) => {
          if (error) {
            console.error('Failed to change report owner because: ' + error)
            return
          }
          this.fetchReportData()
        })
      }
    },
    toggleReportHidden: function (shouldHide) {
      if (shouldHide) {
        reportsApi.hideReport(this.reportLongId, (error) => {
          if (error) {
            console.error('Failed hiding report because: ' + error)
            return
          }
          this.fetchReportData()
        })
      } else {
        reportsApi.unhideReport(this.reportLongId, (error) => {
          if (error) {
            console.error('Failed unhiding report because: ' + error)
            return
          }
          this.fetchReportData()
        })
      }
    },
    cancelShareButton: function () {
      this.accessLinkInfo.label = ''
      this.accessLinkInfo.expiry = ''
      this.accessLinkInfo.longid = ''
      this.accessLinkInfo.token = ''
    },
    editShare: function (row) {
      this.accessLinkInfo.label = row.label
      this.accessLinkInfo.longid = row.longid
      this.accessLinkInfo.token = row.token

      let expiry = null
      if (row.expiry.length > 0 && row.expiry !== 'never') {
        expiry = new Date(row.expiry)
        expiry = (expiry.getMonth() + 1) + '/' + expiry.getDate() + '/' + expiry.getFullYear()
      }
      this.accessLinkInfo.expiry = expiry
      this.$refs['edit-link-modal'].show()
    },
    requestRawData: function (rawDataEntry) {
      rawdataApi.requestRawDataSync(rawDataEntry.uuid, (error) => {
        if (error) {
          console.error('Failed requesting of raw data because: ' + error)
          this.requestRawDataFailed = error
          return
        }
        this.requestRawDataFailed = null
        this.fetchReportData()
      })
    }
  }
}
</script>

<style lang="scss">
</style>
