/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The User model module.
 * @module model/User
 * @version 0.0.1
 */
class User {
    /**
     * Constructs a new <code>User</code>.
     * @alias module:model/User
     */
    constructor() { 
        
        User.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>User</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/User} obj Optional instance to populate.
     * @return {module:model/User} The populated <code>User</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new User();

            if (data.hasOwnProperty('androidVersion')) {
                obj['androidVersion'] = ApiClient.convertToType(data['androidVersion'], 'String');
            }
            if (data.hasOwnProperty('deviceModel')) {
                obj['deviceModel'] = ApiClient.convertToType(data['deviceModel'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('label')) {
                obj['label'] = ApiClient.convertToType(data['label'], 'String');
            }
            if (data.hasOwnProperty('lastLogin')) {
                obj['lastLogin'] = ApiClient.convertToType(data['lastLogin'], 'String');
            }
            if (data.hasOwnProperty('lastReport')) {
                obj['lastReport'] = ApiClient.convertToType(data['lastReport'], 'String');
            }
            if (data.hasOwnProperty('longid')) {
                obj['longid'] = ApiClient.convertToType(data['longid'], 'String');
            }
            if (data.hasOwnProperty('notes')) {
                obj['notes'] = ApiClient.convertToType(data['notes'], 'String');
            }
            if (data.hasOwnProperty('recentReportCount')) {
                obj['recentReportCount'] = ApiClient.convertToType(data['recentReportCount'], 'Number');
            }
            if (data.hasOwnProperty('reportCount')) {
                obj['reportCount'] = ApiClient.convertToType(data['reportCount'], 'Number');
            }
            if (data.hasOwnProperty('roles')) {
                obj['roles'] = ApiClient.convertToType(data['roles'], ['String']);
            }
            if (data.hasOwnProperty('samsungSerial')) {
                obj['samsungSerial'] = ApiClient.convertToType(data['samsungSerial'], 'String');
            }
            if (data.hasOwnProperty('sensorFirmware')) {
                obj['sensorFirmware'] = ApiClient.convertToType(data['sensorFirmware'], 'String');
            }
            if (data.hasOwnProperty('sensorName')) {
                obj['sensorName'] = ApiClient.convertToType(data['sensorName'], 'String');
            }
            if (data.hasOwnProperty('softwareVersion')) {
                obj['softwareVersion'] = ApiClient.convertToType(data['softwareVersion'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('tokenCount')) {
                obj['tokenCount'] = ApiClient.convertToType(data['tokenCount'], 'Number');
            }
            if (data.hasOwnProperty('linked')) {
                obj['linked'] = ApiClient.convertToType(data['linked'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>User</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>User</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['androidVersion'] && !(typeof data['androidVersion'] === 'string' || data['androidVersion'] instanceof String)) {
            throw new Error("Expected the field `androidVersion` to be a primitive type in the JSON string but got " + data['androidVersion']);
        }
        // ensure the json data is a string
        if (data['deviceModel'] && !(typeof data['deviceModel'] === 'string' || data['deviceModel'] instanceof String)) {
            throw new Error("Expected the field `deviceModel` to be a primitive type in the JSON string but got " + data['deviceModel']);
        }
        // ensure the json data is a string
        if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
            throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
        }
        // ensure the json data is a string
        if (data['label'] && !(typeof data['label'] === 'string' || data['label'] instanceof String)) {
            throw new Error("Expected the field `label` to be a primitive type in the JSON string but got " + data['label']);
        }
        // ensure the json data is a string
        if (data['lastLogin'] && !(typeof data['lastLogin'] === 'string' || data['lastLogin'] instanceof String)) {
            throw new Error("Expected the field `lastLogin` to be a primitive type in the JSON string but got " + data['lastLogin']);
        }
        // ensure the json data is a string
        if (data['lastReport'] && !(typeof data['lastReport'] === 'string' || data['lastReport'] instanceof String)) {
            throw new Error("Expected the field `lastReport` to be a primitive type in the JSON string but got " + data['lastReport']);
        }
        // ensure the json data is a string
        if (data['longid'] && !(typeof data['longid'] === 'string' || data['longid'] instanceof String)) {
            throw new Error("Expected the field `longid` to be a primitive type in the JSON string but got " + data['longid']);
        }
        // ensure the json data is a string
        if (data['notes'] && !(typeof data['notes'] === 'string' || data['notes'] instanceof String)) {
            throw new Error("Expected the field `notes` to be a primitive type in the JSON string but got " + data['notes']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['roles'])) {
            throw new Error("Expected the field `roles` to be an array in the JSON data but got " + data['roles']);
        }
        // ensure the json data is a string
        if (data['samsungSerial'] && !(typeof data['samsungSerial'] === 'string' || data['samsungSerial'] instanceof String)) {
            throw new Error("Expected the field `samsungSerial` to be a primitive type in the JSON string but got " + data['samsungSerial']);
        }
        // ensure the json data is a string
        if (data['sensorFirmware'] && !(typeof data['sensorFirmware'] === 'string' || data['sensorFirmware'] instanceof String)) {
            throw new Error("Expected the field `sensorFirmware` to be a primitive type in the JSON string but got " + data['sensorFirmware']);
        }
        // ensure the json data is a string
        if (data['sensorName'] && !(typeof data['sensorName'] === 'string' || data['sensorName'] instanceof String)) {
            throw new Error("Expected the field `sensorName` to be a primitive type in the JSON string but got " + data['sensorName']);
        }
        // ensure the json data is a string
        if (data['softwareVersion'] && !(typeof data['softwareVersion'] === 'string' || data['softwareVersion'] instanceof String)) {
            throw new Error("Expected the field `softwareVersion` to be a primitive type in the JSON string but got " + data['softwareVersion']);
        }
        // ensure the json data is a string
        if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
            throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
        }

        return true;
    }


}



/**
 * @member {String} androidVersion
 */
User.prototype['androidVersion'] = undefined;

/**
 * @member {String} deviceModel
 */
User.prototype['deviceModel'] = undefined;

/**
 * @member {String} email
 */
User.prototype['email'] = undefined;

/**
 * @member {String} label
 */
User.prototype['label'] = undefined;

/**
 * @member {String} lastLogin
 */
User.prototype['lastLogin'] = undefined;

/**
 * @member {String} lastReport
 */
User.prototype['lastReport'] = undefined;

/**
 * @member {String} longid
 */
User.prototype['longid'] = undefined;

/**
 * @member {String} notes
 */
User.prototype['notes'] = undefined;

/**
 * @member {Number} recentReportCount
 */
User.prototype['recentReportCount'] = undefined;

/**
 * @member {Number} reportCount
 */
User.prototype['reportCount'] = undefined;

/**
 * @member {Array.<module:model/User.RolesEnum>} roles
 */
User.prototype['roles'] = undefined;

/**
 * @member {String} samsungSerial
 */
User.prototype['samsungSerial'] = undefined;

/**
 * @member {String} sensorFirmware
 */
User.prototype['sensorFirmware'] = undefined;

/**
 * @member {String} sensorName
 */
User.prototype['sensorName'] = undefined;

/**
 * @member {String} softwareVersion
 */
User.prototype['softwareVersion'] = undefined;

/**
 * @member {module:model/User.StatusEnum} status
 */
User.prototype['status'] = undefined;

/**
 * @member {Number} tokenCount
 */
User.prototype['tokenCount'] = undefined;

/**
 * @member {Boolean} linked
 */
User.prototype['linked'] = undefined;





/**
 * Allowed values for the <code>roles</code> property.
 * @enum {String}
 * @readonly
 */
User['RolesEnum'] = {

    /**
     * value: "admin"
     * @const
     */
    "admin": "admin",

    /**
     * value: "viewall"
     * @const
     */
    "viewall": "viewall",

    /**
     * value: "user"
     * @const
     */
    "user": "user",

    /**
     * value: "restricted"
     * @const
     */
    "restricted": "restricted",

    /**
     * value: "engineering_manager"
     * @const
     */
    "engineering_manager": "engineering_manager",

    /**
     * value: "raw_data"
     * @const
     */
    "raw_data": "raw_data"
};


/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
User['StatusEnum'] = {

    /**
     * value: "active"
     * @const
     */
    "active": "active",

    /**
     * value: "inactive"
     * @const
     */
    "inactive": "inactive"
};



export default User;

