/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';

/**
* Rawdata service.
* @module api/RawdataApi
* @version 0.0.1
*/
export default class RawdataApi {

    /**
    * Constructs a new RawdataApi. 
    * @alias module:api/RawdataApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the rawdataDownloadUuidFormatGet operation.
     * @callback module:api/RawdataApi~rawdataDownloadUuidFormatGetCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} uuid UUID of the rawdata object to download
     * @param {module:model/String} format format of the rawdata file to download
     * @param {module:api/RawdataApi~rawdataDownloadUuidFormatGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    rawdataDownloadUuidFormatGet(uuid, format, callback) {
      let postBody = null;
      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling rawdataDownloadUuidFormatGet");
      }
      // verify the required parameter 'format' is set
      if (format === undefined || format === null) {
        throw new Error("Missing the required parameter 'format' when calling rawdataDownloadUuidFormatGet");
      }

      let pathParams = {
        'uuid': uuid,
        'format': format
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/rawdata/download/{uuid}/{format}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the requestRawDataSync operation.
     * @callback module:api/RawdataApi~requestRawDataSyncCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} uuid UUID of the rawdata object to request synchronization
     * @param {module:api/RawdataApi~requestRawDataSyncCallback} callback The callback function, accepting three arguments: error, data, response
     */
    requestRawDataSync(uuid, callback) {
      let postBody = null;
      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling requestRawDataSync");
      }

      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/rawdata/request/{uuid}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
